import { createTheme } from '@mui/material/styles'

const TimeslotTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#009688'
    },
    secondary: {
      main: '#00bcd4'
    },
    error: {
      main: '#d9564b'
    },
    warning: {
      main: '#cbbc2e'
    },
    info: {
      main: '#23b2f2'
    },
    success: {
      main: '#6ead22'
    },
    background: {
      default: '#4890b3',
      paper: '#e0e0e0'
    },
    text: {
      primary: '#000000',
      secondary: '#607d8b',
      disabled: '#aaaaaa'
    }
  }
})

export default TimeslotTheme
