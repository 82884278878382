import * as React from 'react'

import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule'

import { Grid, Stack } from '@mui/material'

import { supabase } from '../../supabase'

const ONE_HOUR = 1 * 60 * 60 * 1000

async function GetRooms() {
  console.log('get rooms from supabase')
  const roomsValue = await supabase.from('rooms').select('*').eq('is_active', true)

  return roomsValue.data
}

function EditorTemplate(props) {
  console.log(props)
  props.setReccurrence({})

  let eventProps = props.eventProps

  const [subject, setSubject] = React.useState(eventProps.subject)
  const [memo, setMemo] = React.useState(eventProps.memo)
  const [startTime, setStartTime] = React.useState(eventProps.StartTime)
  const [endTime, setEndTime] = React.useState(new Date(eventProps.StartTime.getTime() + ONE_HOUR))
  const [room, setRoom] = React.useState(eventProps.room_id)

  const [rooms, setRooms] = React.useState()
  React.useEffect(() => {
    GetRooms().then((data) => {
      setRooms(data)
    })
  }, [])

  const onStartTimeChanged = (value) => {
    setEndTime(new Date(value.getTime() + ONE_HOUR))
    setStartTime(value)
  }

  return (
    <Grid container spacing={2} style={{ paddingTop: '12px' }} alignItems="flex-end">
      <Grid item xs={6}>
        <label>Subject</label>
        <input
          id="Subject"
          className="e-field e-input"
          name="subject"
          value={subject}
          onChange={(target) => setSubject(target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <label>Memo</label>
        <input
          id="Memo"
          className="e-field e-input"
          name="memo"
          value={memo}
          onChange={(target) => setMemo(target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <label>Start Time</label>
          <DateTimePickerComponent
            format="MM/dd/yy hh:mm a"
            id="StartTime"
            data-name="StartTime"
            value={startTime}
            className="e-field"
            onChange={(target) => onStartTimeChanged(target.value)}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <label>End Time</label>
          <DateTimePickerComponent
            format="MM/dd/yy hh:mm a"
            id="EndTime"
            data-name="EndTime"
            value={endTime}
            className="e-field"
            onChange={(target) => setEndTime(target.value)}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <RecurrenceEditorComponent
          ref={(t) => props.setReccurrence(t)}
          id="RecurrenceEditor"
          value={eventProps.RecurrenceRule}
        />
      </Grid>
      <Grid item xs={6}>
        <label>Room</label>
        <DropDownListComponent
          id="room_id"
          dataSource={rooms}
          fields={{ text: 'name', value: 'id' }}
          placeholder="Room"
          className="e-field"
          value={room}
          onChange={(target) => setRoom(target.value)}
        />
      </Grid>
      <Grid item xs={6} alignItems="center">
        <CheckBoxComponent
          label="Cancelled"
          id="Cancelled"
          name="Cancelled"
          className="e-field"
          checked={eventProps.Cancelled}
        />
      </Grid>
    </Grid>
  )
}

export default EditorTemplate
