import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { CustomDataAdaptor, DataManager } from '@syncfusion/ej2-data'
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week
} from '@syncfusion/ej2-react-schedule'

import EditorTemplate from './EditorTemplate'

import './Schedule.css'
import * as SupaClientAdaptor from '../../adapters/SupaClientAdaptor'

const dataManager = new DataManager({
  adaptor: new CustomDataAdaptor({
    getData: (option) => {
      SupaClientAdaptor.getEvents(option)
    },
    addRecord: (option) => {
      SupaClientAdaptor.addEvent(option)
    },
    updateRecord: (option) => {
      SupaClientAdaptor.updateEvent(option)
    },
    deleteRecord: (option) => {
      SupaClientAdaptor.deleteEvent(option)
    },
    batchUpdate: (option) => {
      SupaClientAdaptor.batchEventUpdate(option)
    }
  })
})

const roomsDataManager = new DataManager({
  adaptor: new CustomDataAdaptor({
    getData: (option) => {
      SupaClientAdaptor.getResources(option)
    },
    addRecord: (option) => {
      SupaClientAdaptor.addResource(option)
    },
    updateRecord: (option) => {
      SupaClientAdaptor.updateResource(option)
    },
    deleteRecord: (option) => {
      SupaClientAdaptor.deleteResource(option)
    },
    batchUpdate: (option) => {
      SupaClientAdaptor.batchResourcesUpdate(option)
    }
  })
})

const fieldsData = {
  id: { name: 'Id' },
  subject: { name: 'subject' },
  location: { name: 'memo' }
}

function Schedule() {
  let scheduler = null
  let recurrObj = null

  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const destroyed = (args) => {
    if (window.location.href.includes('/schedule')) {
      window.location.reload()
    }
  }

  const navigating = (args) => {
    if (args.action === 'view') {
      if (args.currentView === 'Week') {
        scheduler.enableAdaptiveUI = true
      } else {
        scheduler.enableAdaptiveUI = false
      }
    }
  }

  const onActionBegin = (args) => {
    if (args.requestType === 'toolbarItemRendering') {
      const settingsButton = {
        align: 'Right',
        prefixIcon: 'e-icons e-settings',
        tooltipText: 'Settings',
        cssClass: 'settings-button'
      }
      args.items.push(settingsButton)
    }
  }

  const onActionComplete = (args) => {
    const scheduleElement = document.getElementById('scheduler')
    if (args.requestType === 'toolBarItemRendered' && scheduleElement) {
      const settingsButton = scheduleElement.querySelector('.settings-button')
      if (settingsButton) {
        settingsButton.onclick = () => {
          history.push('/settings')
        }
      }
    }
  }

  const onEventRendered = (event) => {
    if (event.data.Cancelled) {
      event.element.style.backgroundColor = '#AEAEAE'
    }
  }

  const popupOpen = (args) => {
    console.log(args.type)
    switch (args.type) {
      case 'RecurrenceAlert':
        console.log(args)
        if (args.data.RecurrenceException) {
          args.cancel = true
          scheduler.openEditor(args.data, 'EditOccurrence')
        }
        break

      case 'Editor':
        args.duration = 60
        break

      default:
        break
    }
  }

  const popupClose = (args) => {
    if (args.type === 'Editor') {
      if (args.data) {
        if (recurrObj.value) {
          args.data.RecurrenceRule = recurrObj.value
        }
      }
    }
  }

  const setReccurrence = (args) => {
    recurrObj = args
  }

  const editorTemplate = (eventProps) => {
    if (Object.keys(eventProps).length === 0) return

    return <EditorTemplate eventProps={eventProps} setReccurrence={setReccurrence} />
  }

  return (
    <ScheduleComponent
      eventSettings={{
        dataSource: dataManager,
        fields: fieldsData,
        editFollowingEvents: true
      }}
      width="100%"
      height="100vh"
      id="scheduler"
      cssClass="scheduler"
      currentView="Day"
      workHours={{ highlight: true, start: '06:00', end: '20:00' }}
      group={{ resources: ['Rooms'] }}
      enableAdaptiveUI={false}
      navigating={navigating}
      destroyed={destroyed}
      popupOpen={popupOpen}
      popupClose={popupClose}
      ref={(obj) => {
        scheduler = obj
      }}
      editorTemplate={editorTemplate}
      eventRendered={onEventRendered}
      actionBegin={onActionBegin}
      actionComplete={onActionComplete}>
      <ResourcesDirective>
        <ResourceDirective
          field="room_id"
          title="Room Type"
          name="Rooms"
          allowMultiple={true}
          dataSource={roomsDataManager}
          textField="name"
          idField="id"
          colorField="color"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="Day" group={{ enableCompactView: false, byDate: true }} />
        <ViewDirective option="Week" group={{ enableCompactView: true, byDate: false }} />
      </ViewsDirective>
      <Inject services={[Day, Week]} />
    </ScheduleComponent>
  )
}

export default Schedule
