import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import { Alert } from '@mui/material'

function SlideTransition(props) {
  return <Slide {...props} direction="left" />
}

const ToastContext = React.createContext(undefined)

export const ToastProvider = ({ children }) => {
  const [state, setState] = React.useState({
    open: false,
    Transition: SlideTransition,
    severity: 'success',
    message: 'This is a success Alert inside a Snackbar!'
  })

  const showToast = (options) => {
    options.open = true
    setState({ ...state, ...options })
  }

  const handleClose = () => {
    setState({
      ...state,
      open: false
    })
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Snackbar
        open={state.open}
        TransitionComponent={state.Transition}
        key={state.Transition.name}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={state.severity}
          variant="filled"
          sx={{ width: '100%' }}>
          {state.message}
        </Alert>
      </Snackbar>
      <div>{children}</div>
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = React.useContext(ToastContext)

  if (!context) {
    throw new Error('useToastContext have to be used within ToastProvider')
  }

  return context
}
