import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { registerLicense } from '@syncfusion/ej2-base'

import './App.css'
import { AuthProvider } from './contexts/Auth'
import Login from './components/Login/Login'
import { PrivateRoute } from './components/PrivateRoute'
import Schedule from './components/Schedule/Schedule'
import Settings from './components/Settings/Settings'

// Registering Syncfusion license key
const syncfusion_license_key = process.env.REACT_APP_SYNCFUSION_LICENSE_KEY
registerLicense(syncfusion_license_key)

function App() {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/">
          <Redirect to="/schedule" />
        </Route>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/schedule" component={Schedule} />
        <PrivateRoute path="/settings" component={Settings} />
      </Switch>
    </AuthProvider>
  )
}

export default App
