import * as React from 'react'
import { supabase } from '../../supabase'

import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'

import { AddCircle, Delete } from '@mui/icons-material'

import './Settings.css'
import { useAuth } from '../../contexts/Auth'

const maxAllowedUsers = 100

function RoleSelector({ roles, selectedRole, onChange }) {
  if (roles.length == 0) return

  return (
    <FormControl variant="standard">
      <InputLabel>Role</InputLabel>
      <Select
        defaultValue=""
        value={selectedRole}
        label="Role"
        size="small"
        sx={{ minWidth: 100 }}
        onChange={(event) => onChange(event.target.value)}>
        {roles.map((role) => {
          if (role.id == 3) return

          return (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

function ExistingUser({ user, roles, deleteUser }) {
  const [role_id, setRoleId] = React.useState(user.role_id)

  const roleChanged = (role_id) => {
    supabase
      .from('users')
      .update({ role_id: role_id })
      .eq('uid', user.uid)
      .select()
      .then((result) => {
        if (result.error) {
          console.log(result.error)
        } else {
          setRoleId(result.data[0].role_id)
        }
      })
  }

  return (
    <Grid
      container
      item
      spacing={2}
      columns={{ xs: 4, sm: 8, md: 8 }}
      justifyContent="flex-end"
      alignItems="center">
      {role_id != 3 && (
        <React.Fragment>
          <Grid item xs={5}>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <RoleSelector roles={roles} selectedRole={role_id} onChange={roleChanged} />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                deleteUser(user.uid)
              }}>
              <Delete />
            </IconButton>
          </Grid>
        </React.Fragment>
      )}

      {role_id == 3 && (
        <React.Fragment>
          <Grid item xs={5}>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="standard">
              <InputLabel>Role</InputLabel>
              <Select disabled defaultValue={3} label="Role" size="small" sx={{ minWidth: 100 }}>
                <MenuItem key={1} value={3}>
                  Owner
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <IconButton />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

function NewUser({ users, roles, addUser }) {
  const [role_id, setRoleId] = React.useState(2)
  const email = React.useRef('')

  if (users.length > maxAllowedUsers) {
    return (
      <Grid
        container
        item
        spacing={2}
        // columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        justifyContent="flex-end"
        alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="center" alignItems="center">
          <Button
            variant="outlined"
            sx={{
              borderColor: '#FF4742',
              borderRadius: 3,
              background: '#FF4742',
              fontSize: 14,
              color: '#FFFFFF'
            }}>
            Maximum number of users already added
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      item
      spacing={2}
      columns={{ xs: 4, sm: 8, md: 8 }}
      justifyContent="flex-end"
      alignItems="center">
      <Grid item xs={5}>
        <TextField placeholder="Email" variant="standard" inputRef={email} />
      </Grid>
      <Grid item xs={2}>
        <RoleSelector
          roles={roles}
          selectedRole={role_id}
          onChange={(role_id) => setRoleId(role_id)}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            if (email.current.value == '' || role_id == '') return

            addUser(email.current.value, role_id)
            email.current.value = ''
            setRoleId('')
          }}>
          <AddCircle />
        </IconButton>
      </Grid>
    </Grid>
  )
}

function Users() {
  const [users, setUsers] = React.useState([])
  const [roles, setRoles] = React.useState([])

  const { session } = useAuth()
  React.useEffect(() => {
    supabase.functions
      .invoke('get-users', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      })
      .then(({ data: users, error }) => {
        if (error) {
          console.log(error)
        } else {
          setUsers(users)
        }
      })

    supabase
      .from('roles')
      .select('*')
      .then((data) => {
        setRoles(data.data)
      })
  }, [])

  const addUser = (email, role_id) => {
    supabase.functions
      .invoke('create-user', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ email: email, role: role_id })
      })
      // eslint-disable-next-line no-unused-vars
      .then(({ data: user, error }) => {
        if (error) {
          console.log(error)
        } else {
          supabase
            .from('users')
            .select('*')
            .then(({ data: users, error }) => {
              if (error) {
                console.log(error)
              } else {
                setUsers(users)
              }
            })
        }
      })
  }

  const deleteUser = (uid) => {
    supabase.functions
      .invoke('delete-user', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ uid: uid })
      })
      // eslint-disable-next-line no-unused-vars
      .then(({ data: user, error }) => {
        if (error) {
          console.log(error)
        } else {
          supabase
            .from('users')
            .select('*')
            .then(({ data: users, error }) => {
              if (error) {
                console.log(error)
              } else {
                setUsers(users)
              }
            })
        }
      })
  }

  return (
    <Grid container spacing={2}>
      {users.map((user) => {
        return <ExistingUser key={user.uid} user={user} roles={roles} deleteUser={deleteUser} />
      })}
      <Grid item xs={12}>
        <Divider>Add Users</Divider>
      </Grid>
      <NewUser users={users} roles={roles} addUser={addUser} />
    </Grid>
  )
}

export default Users
