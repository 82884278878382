import * as React from 'react'
import { supabase } from '../../supabase'
import { CirclePicker } from 'react-color'

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography
} from '@mui/material'

import { Clear, Delete, FormatPaint } from '@mui/icons-material'

import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'

import './Settings.css'

const maxAllowedResources = 15

async function GetRooms() {
  return await supabase
    .from('rooms')
    .select('*')
    .eq('is_active', true)
    .order('name', { ascending: true })
}

async function DeleteResource(resource_id, setRooms) {
  await supabase.from('rooms').update({ is_active: false }).eq('id', resource_id)
  const rooms = await GetRooms()
  setRooms(rooms.data)
}

async function CreateResource(resourceName, resourceColor, setResources) {
  const customers = await supabase.from('customers').select('*')
  const newResource = {
    name: resourceName,
    color: resourceColor,
    customer_id: customers.data[0].id
  }
  await supabase.from('rooms').insert(newResource)
  const rooms = await GetRooms()
  setResources(rooms.data)
}

async function UpdateResource(resourceId, resourceName, resourceColor, setResources) {
  const existingResource = await supabase.from('rooms').select('*').eq('id', resourceId)
  const currentResourceData = existingResource.data[0]
  const newResource = {
    customer_id: currentResourceData.customer_id,
    id: currentResourceData.id,
    notes: currentResourceData.notes
  }
  if (resourceColor.length > 0) {
    newResource.color = resourceColor
  }
  if (resourceName.length > 0) {
    newResource.name = resourceName
  }
  await supabase.from('rooms').update(newResource).eq('id', resourceId)
  const rooms = await GetRooms()
  setResources(rooms.data)
}

function SingleResource({ resource, setResources }) {
  const [edit, setEdit] = React.useState(false)
  const endEdit = () => setEdit(false)

  if (edit) {
    return <EditResource resource={resource} setResources={setResources} endEdit={endEdit} />
  }

  return (
    <Grid
      container
      item
      spacing={2}
      columns={{ xs: 4, sm: 8, md: 8 }}
      justifyContent="flex-end"
      alignItems="center">
      <Grid item xs={3} sm={4}>
        <Typography>{resource.name}</Typography>
      </Grid>
      <Grid item xs={1} sm={4}>
        <IconButton
          onClick={() => {
            // handleOpen()
            setEdit(true)
          }}
          sx={{
            bgcolor: resource.color
          }}>
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

function ExistingResources({ resources, setResources }) {
  React.useEffect(() => {
    GetRooms().then((data) => {
      setResources(data.data)
    })
  }, [])

  return resources.map((resource) => {
    return <SingleResource key={resource.id} resource={resource} setResources={setResources} />
  })
}

function Resources() {
  const [resources, setResources] = React.useState([])

  return (
    <Grid container spacing={2}>
      <ExistingResources resources={resources} setResources={setResources} />
      <Grid item xs={12}>
        <Divider>Add Resource</Divider>
      </Grid>
      <AddResource setResources={setResources} resources={resources} />
    </Grid>
  )
}

function ColorModal({ setResourceColor, handleClose, colorModal }) {
  // eslint-disable-next-line no-unused-vars
  function handleColorChange(color, event) {
    setResourceColor(color.hex)
    handleClose()
  }

  return (
    <Modal
      open={colorModal}
      onClose={handleClose}
      aria-labelledby="set color for resource"
      aria-describedby="set color for resource"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper sx={{ padding: 2 }}>
        <CirclePicker onChange={handleColorChange} />
      </Paper>
    </Modal>
  )
}

function EditResource({ resource, setResources, endEdit }) {
  const [resourceColor, setResourceColor] = React.useState(resource.color)
  const [colorModal, setColorModal] = React.useState(false)

  const resourceNameRef = React.useRef('')
  const handleOpen = () => setColorModal(true)
  const handleClose = () => setColorModal(false)

  return (
    <Grid
      container
      item
      spacing={2}
      columns={{ xs: 4, sm: 8, md: 8 }}
      justifyContent="flex-end"
      alignItems="center">
      <Grid item xs={4} sm={4}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Resource Name"
          inputRef={resourceNameRef}
          defaultValue={resource.name}
          variant="standard"
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <IconButton
          onClick={() => {
            handleOpen()
          }}
          sx={{
            bgcolor: resourceColor
          }}>
          <FormatPaint />
        </IconButton>
      </Grid>

      <Grid item xs={1} sm={1}>
        <IconButton
          onClick={() => {
            UpdateResource(resource.id, resourceNameRef.current.value, resourceColor, setResources)
            resourceNameRef.current.value = ''
            endEdit()
          }}>
          <SaveIcon />
        </IconButton>
      </Grid>

      <Grid item xs={1} sm={1}>
        <IconButton
          onClick={() => {
            DeleteResource(resource.id, setResources)
          }}>
          <Delete />
        </IconButton>
      </Grid>

      <Grid item xs={1} sm={1}>
        <IconButton onClick={() => endEdit()}>
          <Clear />
        </IconButton>
      </Grid>

      <ColorModal
        setResourceColor={setResourceColor}
        handleClose={handleClose}
        colorModal={colorModal}
      />
    </Grid>
  )
}

function AddResource({ setResources, resources }) {
  const [resourceName, setResourceName] = React.useState([])
  const [resourceColor, setResourceColor] = React.useState([])
  const [colorModal, setColorModal] = React.useState(false)
  const handleOpen = () => setColorModal(true)
  const handleClose = () => setColorModal(false)

  if (resources.length > maxAllowedResources) {
    return (
      <Grid
        container
        item
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
        justifyContent="flex-start"
        alignItems="center">
        <Grid item xs={8} sm={5} md={5} lg={7} justifyContent="center" alignItems="center">
          <Button
            variant="outlined"
            sx={{
              borderColor: '#FF4742',
              borderRadius: 3,
              background: '#FF4742',
              fontSize: 14,
              color: '#FFFFFF'
            }}>
            Maximum number of resources already created
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      item
      spacing={2}
      columns={{ xs: 4, sm: 8, md: 8 }}
      justifyContent="flex-start"
      alignItems="center">
      <Grid item xs={3} sm={4}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Resource Name"
          variant="standard"
          onChange={(e) => {
            setResourceName(e.target.value)
          }}
        />
      </Grid>

      <Grid item xs={1} sm={1}>
        <IconButton
          onClick={() => {
            handleOpen()
          }}
          sx={{
            bgcolor: resourceColor
          }}>
          <FormatPaint />
        </IconButton>
      </Grid>

      <Grid item xs={4} sm={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            CreateResource(resourceName, resourceColor, setResources)
          }}>
          Create
        </Button>
      </Grid>

      <ColorModal
        setResourceColor={setResourceColor}
        handleClose={handleClose}
        colorModal={colorModal}
      />
    </Grid>
  )
}

export default Resources
