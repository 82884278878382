import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Card, Divider, Stack, TextField } from '@mui/material'

import './Login.css'
import logo from '../../timeslot_color.png'
import { useAuth } from '../../contexts/Auth'
import { useToast } from '../../contexts/Toast'
import { supabase } from '../../supabase'

function Login() {
  const emailRef = React.useRef()
  const passwordRef = React.useRef()

  const [forgotPassword, setForgotPassword] = React.useState(false)

  const endForgot = () => setForgotPassword(false)
  const startForgot = () => setForgotPassword(true)
  const { signIn } = useAuth()
  const { showToast } = useToast()
  const history = useHistory()

  async function submit(e) {
    e.preventDefault()

    const email = emailRef.current.value
    const password = passwordRef.current.value

    const { error } = await signIn({ email, password })

    if (error) {
      showToast({
        severity: 'error',
        message: error.message
      })
    } else {
      history.push('/schedule')
    }
  }

  async function sendEmailToLogin(email) {
    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        shouldCreateUser: false,
        emailRedirectTo: 'http://localhost:3000/settings'
      }
    })

    if (error) {
      console.log(error)
      showToast({
        severity: 'error',
        message: error.message
      })
    } else {
      showToast({
        severity: 'success',
        message: 'Recovery email sent'
      })
      endForgot()
    }
  }

  if (forgotPassword) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh', backgroundColor: '#4890b3' }}>
        <Card
          sx={{
            minWidth: '20vw',
            maxWidth: '80vw',
            padding: 2,
            borderRadius: 5
          }}>
          <form onSubmit={submit}>
            <Stack spacing={2} alignItems="center">
              <img src={logo} alt="Timeslot" style={{ width: '100%' }} />
              <TextField
                required
                label="Email"
                type="email"
                name="email"
                variant="standard"
                inputRef={emailRef}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                onClick={() => {
                  sendEmailToLogin(emailRef.current.value)
                }}>
                Send Recovery Email
              </Button>
              <Divider sx={{ width: '100%' }} />
              <Button fullWidth onClick={endForgot}>
                go back
              </Button>
            </Stack>
          </form>
        </Card>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh', backgroundColor: '#4890b3' }}>
      <Card
        sx={{
          minWidth: '20vw',
          maxWidth: '80vw',
          padding: 2,
          borderRadius: 5
        }}>
        <form onSubmit={submit}>
          <Stack spacing={2} alignItems="center">
            <img src={logo} alt="Timeslot" style={{ width: '100%' }} />
            <TextField
              required
              label="Email"
              type="email"
              name="email"
              variant="standard"
              inputRef={emailRef}
              fullWidth
            />
            <TextField
              required
              label="Password"
              type="password"
              name="password"
              variant="standard"
              inputRef={passwordRef}
              fullWidth
            />
            <Button type="submit" variant="contained" fullWidth>
              Login
            </Button>
            <Divider sx={{ width: '100%' }} />
            <Button fullWidth onClick={startForgot}>
              Forgot Password
            </Button>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default Login
