import * as React from 'react'
import { useHistory } from 'react-router-dom'

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Toolbar,
  Typography
} from '@mui/material'

import { ArrowBackIosNew, Home, People, Person } from '@mui/icons-material'

import Profile from './Profile'
import Resources from './Resources'
import Users from './Users'

import './Settings.css'
import { supabase } from '../../supabase'
import { useAuth } from '../../contexts/Auth'

const drawerWidth = 60

function SideBarResources({ setShowProfile, setShowUsers, setShowResources, isAdmin }) {
  if (isAdmin) {
    return (
      <ListItem key={'Home'} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          onClick={() => {
            setShowProfile(false)
            setShowUsers(false)
            setShowResources(true)
          }}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    )
  }
  return null
}

function SideBarUsers({ setShowProfile, setShowUsers, setShowResources, isAdmin }) {
  if (isAdmin) {
    return (
      <ListItem key={'People'} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          onClick={() => {
            setShowProfile(false)
            setShowUsers(true)
            setShowResources(false)
          }}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    )
  }
  return null
}

function Settings() {
  const history = useHistory()

  const [showProfile, setShowProfile] = React.useState(true)
  const [showUsers, setShowUsers] = React.useState(false)
  const [showResources, setShowResources] = React.useState(false)
  const [admin, setAdmin] = React.useState(false)

  const { session } = useAuth()

  React.useEffect(() => {
    async function isAdmin() {
      const { data } = await supabase
        .from('users')
        .select('customers!public_users_customer_fkey (name), roles (name)')
        .eq('uid', session.user.id)
      if (data[0].roles.name === 'Owner' || data[0].roles.name === 'Admin') {
        setAdmin(true)
      } else {
        setAdmin(false)
      }
    }

    isAdmin()
  }, [])

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      height="100vh"
      sx={{ backgroundColor: { xs: '#e0e0e0', sm: '#ffffff' } }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#4890b3' }}>
        <Toolbar>
          <IconButton
            size="large"
            onClick={() => {
              history.goBack()
            }}>
            <ArrowBackIosNew sx={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          overflowX: 'hidden',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}>
        <Toolbar />
        <Box sx={{ overflow: 'hidden' }}>
          <List>
            <ListItem key={'Profile'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  setShowProfile(true)
                  setShowUsers(false)
                  setShowResources(false)
                }}>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
          <SideBarUsers
            setShowProfile={setShowProfile}
            setShowUsers={setShowUsers}
            setShowResources={setShowResources}
            isAdmin={admin}
          />
          <SideBarResources
            setShowProfile={setShowProfile}
            setShowUsers={setShowUsers}
            setShowResources={setShowResources}
            isAdmin={admin}
          />
        </Box>
      </Drawer>

      <Box
        display="flex"
        sx={{
          maxWidth: { sm: 'sm' },
          mt: { xs: '56px', sm: '80px' }
        }}>
        <Paper sx={{ p: 2, boxShadow: { xs: 0, sm: 3 } }}>
          {showProfile && <Profile />}
          {showUsers && <Users />}
          {showResources && <Resources />}
        </Paper>
      </Box>
    </Box>
  )
}

export default Settings
