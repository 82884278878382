import * as React from 'react'
import { supabase } from '../../supabase'

import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'

import { Clear, Edit, Save, VisibilityOff, Visibility } from '@mui/icons-material'

import { useAuth } from '../../contexts/Auth'

import './Settings.css'

function Profile() {
  const [user, setUser] = React.useState(undefined)
  const [users, setUsers] = React.useState([])
  const [showPassword, setShowPassword] = React.useState(false)
  const [editEmail, setEditEmail] = React.useState(false)
  const [newOwner, setNewOwner] = React.useState(undefined)

  const emailRef = React.useRef('')
  const passwordRef = React.useRef('')

  const { updateUser, signOut, session } = useAuth()

  React.useEffect(() => {
    supabase
      .from('users')
      .select('customers!public_users_customer_fkey (name), roles (name)') // I really don't know why this customers! nonsense is needed but it is.
      .eq('uid', session.user.id)
      .single()
      .then(({ data: user, error }) => {
        if (error) {
          console.log('error: ', error)
        } else {
          console.log('user in callbac: ', user)
          user.email = session.user.email
          setUser(user)
        }
      })

    supabase.functions
      .invoke('get-users', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      })
      .then(({ data: users, error }) => {
        if (error) {
          console.log(error)
        } else {
          console.log(users)
          setUsers(users)
        }
      })
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  async function saveEmail() {
    updateUser({ email: emailRef.current.value }).then(({ error }) => {
      if (error) {
        console.log(error)
        return
      } else {
        supabase
          .from('users')
          .update({ email: emailRef.current.value })
          .eq('uid', session.user.id)
          .then(({ data, error }) => {
            if (error) {
              console.log(error)
              return
            } else {
              console.log(data)
              setEditEmail(false)
            }
          })
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  async function savePassword(e) {
    updateUser({ password: passwordRef.current.value }).then(({ error }) => {
      if (error) {
        console.log(error)
      } else {
        passwordRef.current.value = ''
      }
    })
  }

  async function transferOwnership() {
    supabase
      .from('users')
      .update({ role_id: 3 })
      .eq('uid', newOwner)
      .then(({ error }) => {
        if (error) {
          console.log(error)
        } else {
          supabase
            .from('users')
            .update({ role_id: 1 })
            .eq('uid', session.user.id)
            .then(({ error }) => {
              if (error) {
                console.log(error)
              } else {
                window.location.reload()
              }
            })
        }
      })
  }

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} columns={{ xs: 4, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={2}>
          <Typography variant="button" fontWeight="bold">
            Customer:
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4}>
          <Typography>{user?.customers.name}</Typography>
        </Grid>
        <Grid item xs={1} sm={2}></Grid>
      </Grid>

      {!editEmail && (
        <Grid
          container
          item
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 8 }}
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={4} sm={2}>
            <Typography variant="button" fontWeight="bold">
              Email:
            </Typography>
          </Grid>

          <Grid item xs={3} sm={4}>
            <Typography>{user?.email}</Typography>
          </Grid>
          <Grid item xs={1} sm={1}>
            <IconButton onClick={() => setEditEmail(true)}>
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      )}

      {editEmail && (
        <Grid
          container
          item
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 8 }}
          justifyContent="flex-end"
          alignItems="center">
          <Grid item xs={4} sm={2}>
            <Typography variant="button" fontWeight="bold">
              Email:
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              inputRef={emailRef}
              defaultValue={user?.email}>
              {user?.email}
            </TextField>
          </Grid>
          <Grid item xs={1} sm={1}>
            <IconButton onClick={() => saveEmail()}>
              <Save />
            </IconButton>
          </Grid>
          <Grid item xs={1} sm={1}>
            <IconButton onClick={() => setEditEmail(false)}>
              <Clear />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={2} columns={{ xs: 4, sm: 8, md: 8 }}>
        <Grid item xs={4} sm={2}>
          <Typography variant="button" fontWeight="bold">
            Role:
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4}>
          <Typography>{user?.roles.name}</Typography>
        </Grid>
        <Grid item xs={1} sm={2}></Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid container item spacing={2} columns={{ xs: 4, sm: 8, md: 8 }}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password" size="small">
              New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              fullWidth
              size="small"
              type={showPassword ? 'text' : 'password'}
              inputRef={passwordRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={savePassword}>
            Change Password
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" onClick={signOut}>
          Logout
        </Button>
      </Grid>

      {user?.roles.name == 'Owner' && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider>Danger Zone</Divider>
          </Grid>

          <Grid container item spacing={2} columns={{ xs: 4, sm: 8, md: 8 }}>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Admins</InputLabel>
                <Select
                  defaultValue=""
                  label="Admins"
                  sx={{ minWidth: 150 }}
                  onChange={(event) => setNewOwner(event.target.value)}>
                  {users.map((user, i) => {
                    if (user.role_id != 1) return

                    return (
                      <MenuItem key={i} value={user.uid}>
                        {user.email}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Button variant="contained" fullWidth onClick={transferOwnership} color="error">
                Transfer Ownership
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

export default Profile
